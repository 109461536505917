export default {
    namespaced: true,
    state: {
        globalLocale: 'oz'
    },
    getters: {
        getGlobalLocale(state) {
            return state.globalLocale;
        }
    },
    mutations: {
        UPDATE_GLOBAL_LOCALE(state, newGlobalLocale) {
            state.globalLocale = newGlobalLocale;
        }
    },
    actions: {
        updateGlobalLocale({commit}, newGlobalLocale) {
            if (newGlobalLocale === "o'z") {
                newGlobalLocale = 'oz';
            }
            if (newGlobalLocale === "ўз") {
                newGlobalLocale = 'uz';
            }

            commit('UPDATE_GLOBAL_LOCALE', newGlobalLocale);
        }
    },
}