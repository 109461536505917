export const ERROR_CODES = {
    ImageIsNotProcessable: 1,

    QualityEstimationFailed: 2,

    ImageIsBlurred: 3,
    ImageIsHighlighted: 4,
    ImageIsDark: 5,
    ImageIsIlluminated: 6,
    ImageIsSpecular: 7,

    ImageContainsNoFaces: 8,
    ImageContainsMultipleFaces: 9,

    FrameWidthIsNotEnough: 10,
    FrameHeightIsNotEnough: 11,

    FaceWidthIsNotEnough: 12,
    FaceHeightIsNotEnough: 13,

    FaceIsCloseToTop: 14,
    FaceIsCloseToRight: 15,
    FaceIsCloseToBottom: 16,
    FaceIsCloseToLeft: 17,

    HeadposeEstimationFailed: 18,

    HeadYawIsTooHigh: 19,
    HeadYawIsTooLow: 20,
    HeadRollIsTooHigh: 21,
    HeadRollIsTooLow: 22,
    HeadPitchIsTooHigh: 23,
    HeadPitchIsTooLow: 24,

    MouthEstimationFailed: 25,
    MouthIsNotVisible: 26,

    EyeglassesEstimationFailed: 27,
    EyeglassesTypeIsNotAllowed: 28,

    AGSEstimationFailed: 29,
    AGSIsLow: 30,

    LivenessEstimationFailed: 31,
    LivenessScoreIsTooLow: 32,
    LivenessQualityScoreIsTooLow: 33,

    DetectionFailed: 34,

    LivenessNotEnoughData: 37,

    FaceIsTooBig: 38,

    FaceLandmarks5TransformationFailed: 40,

    EyesEstimationFailed: 50,
    EyesShouldBeOpen: 51,

    InteractionUnknownError: 60,
    InteractionsOpenEyes: 61,
    InteractionsCloseEyes: 62,
    InteractionsYawStartPosition: 63,
    InteractionsYawRight: 64,
    InteractionsYawLeft: 65,
    InteractionsPitchStartPosition: 66,
    InteractionsPitchUp: 67,
    InteractionsPitchDown: 68,
};

export const ERROR_MESSAGES_RU = {
    1: 'Ошибка: код 1',
    8: 'Наведите камеру на лицо',
    9: 'Должно быть одно лицо',
    34: 'Ошибка: код 34',

    2: 'Ошибка: код 2',
    3: 'Изображение размыто',
    29: 'Ошибка: код 29',
    30: 'Низкое качество кадра',

    4: 'Нужно меньше света',
    6: 'Устраните неравномерное освещение',
    7: 'Устраните блики',

    5: 'Нужно больше света',

    10: 'Ошибка: код 10',
    11: 'Ошибка: код 11',
    12: 'Ближе к камере',
    13: 'Ближе к камере',

    14: 'Камеру ниже',
    15: 'Камеру левее',
    16: 'Камеру выше',
    17: 'Камеру правее',
    18: 'Ошибка: код 18',
    19: 'Поверните лицо вправо',
    20: 'Поверните лицо влево',
    21: 'Голова слишком наклонена вправо',
    22: 'Голова слишком наклонена влево',
    23: 'Поднимите подбородок',
    24: 'Опустите подбородок',

    25: 'Ошибка: код 25',
    26: 'Уберите перекрытие лица',

    27: 'Ошибка: код 27',
    28: 'Снимите очки',

    31: 'Ошибка: код 31',
    32: 'Продолжайте фотографирование',
    33: 'Низкое качество изображения лица',

    37: 'Продолжайте',

    38: 'Камеру дальше от лица',

    40: 'Ошибка 40',

    50: 'Ошибка 50',
    51: 'Откройте глаза',

    60: 'Ошибка 60',
    61: 'Откройте глаза',
    62: 'Закройте глаза',
    63: 'Смотрите в камеру прямо',
    64: 'Поверните голову направо',
    65: 'Поверните голову налево',
    66: 'Смотрите в камеру прямо',
    67: 'Поднимите подбородок',
    68: 'Опустите подбородок',
};

export const ERROR_MESSAGES_UZ = {
    1: 'Хато: код 1',
    8: 'Камерани юзингизга қаратинг',
    9: 'Камерада битта юз бўлиши керак',
    34: 'Хато: код 34',

    2: 'Хато: код 2',
    3: 'Ҳира, бўялган ёки тушунарсиз тасвир',
    29: 'Хато: код 29',
    30: 'Кадрнинг сифати жуда паст',

    4: 'Ёруғликни камайтиринг',
    6: 'Ортиқча ёруғликни бартараф қилинг',
    7: 'Ёрқинликларни олиб ташланг',

    5: 'Кўпроқ ёруғлик керак',

    10: 'Хато: код 10',
    11: 'Хато: код 11',
    12: 'Камерага яқинрок',
    13: 'Камерага яқинрок',

    14: 'Камерани пастроқ қаратинг',
    15: 'Камерани чапроқ қаратинг',
    16: 'Камерани тепароқ қаратинг',
    17: 'Камерани ўнгроқ қаратинг',
    18: 'Хато: код 18',
    19: 'Юзингизни ўнга буринг',
    20: 'Юзингизни чапга буринг',
    21: 'Бошингиз жуда ўнга егилган',
    22: 'Бошингиз жуда чапга егилган',
    23: 'Бошингизни кўтаринг',
    24: 'Бошингизни туширинг',

    25: 'Хато: код 25',
    26: 'Юзингизни ёпадиган нарсаларни олиб ташланг',

    27: 'Хато: код 27',
    28: 'Кўзойнакларингизни ечиб олинг',

    31: 'Хато: код 31',
    32: 'Суратга олишни давом этинг',
    33: 'Юз тасвирининг паст сифати',

    37: 'Давом этинг',

    38: 'Камерани узоқроқ',

    40: 'Хато 40',

    50: 'Хато 50',
    51: 'Кўзингизни очинг',

    60: 'Хато 60',
    61: 'Кўзингизни очинг',
    62: 'Кўзингизни юминг',
    63: 'Камерага қаранг',
    64: 'Бошингизни ўнга буринг',
    65: 'Бошингизни чапга буринг',
    66: 'Камерага қаранг',
    67: 'Бошингизни кўтаринг',
    68: 'Бошингизни туширинг',
};

export const ERROR_MESSAGES_KAA = {
    1: 'Хато: код 1',
    8: 'Камерани юзингизга қаратинг',
    9: 'Камерада битта юз бўлиши керак',
    34: 'Хато: код 34',

    2: 'Хато: код 2',
    3: 'Ҳира, бўялган ёки тушунарсиз тасвир',
    29: 'Хато: код 29',
    30: 'Кадрнинг сифати жуда паст',

    4: 'Ёруғликни камайтиринг',
    6: 'Ортиқча ёруғликни бартараф қилинг',
    7: 'Ёрқинликларни олиб ташланг',

    5: 'Кўпроқ ёруғлик керак',

    10: 'Хато: код 10',
    11: 'Хато: код 11',
    12: 'Камерага яқинрок',
    13: 'Камерага яқинрок',

    14: 'Камерани пастроқ қаратинг',
    15: 'Камерани чапроқ қаратинг',
    16: 'Камерани тепароқ қаратинг',
    17: 'Камерани ўнгроқ қаратинг',
    18: 'Хато: код 18',
    19: 'Юзингизни ўнга буринг',
    20: 'Юзингизни чапга буринг',
    21: 'Бошингиз жуда ўнга егилган',
    22: 'Бошингиз жуда чапга егилган',
    23: 'Бошингизни кўтаринг',
    24: 'Бошингизни туширинг',

    25: 'Хато: код 25',
    26: 'Юзингизни ёпадиган нарсаларни олиб ташланг',

    27: 'Хато: код 27',
    28: 'Кўзойнакларингизни ечиб олинг',

    31: 'Хато: код 31',
    32: 'Суратга олишни давом этинг',
    33: 'Юз тасвирининг паст сифати',

    37: 'Давом этинг',

    38: 'Камерани узоқроқ',

    40: 'Хато 40',

    50: 'Хато 50',
    51: 'Кўзингизни очинг',

    60: 'Хато 60',
    61: 'Кўзингизни очинг',
    62: 'Кўзингизни юминг',
    63: 'Камерага қаранг',
    64: 'Бошингизни ўнга буринг',
    65: 'Бошингизни чапга буринг',
    66: 'Камерага қаранг',
    67: 'Бошингизни кўтаринг',
    68: 'Бошингизни туширинг',
};

export const ERROR_MESSAGES_OZ = {
    1: 'Xato: kod 1',
    8: 'Kamerani yuzingizga qarating',
    9: 'Kamerada bitta yuz bo‘lishi kerak',
    34: 'Xato: kod 34',

    2: 'Xato: kod 2',
    3: 'Hira, bo‘yalgan yoki tushunarsiz tasvir',
    29: 'Xato: kod 29',
    30: 'Kadrning sifati juda past',

    4: 'Yorug‘likni kamaytiring',
    6: 'Ortiqcha yorug‘likni bartaraf qiling',
    7: 'Yorqinliklarni olib tashlang',

    5: 'Ko‘proq yorug‘lik kerak',

    10: 'Xato: kod 10',
    11: 'Xato: kod 11',
    12: 'Kameraga yaqinrok',
    13: 'Kameraga yaqinrok',

    14: 'Kamerani pastroq qarating',
    15: 'Kamerani chaproq qarating',
    16: 'Kamerani teparoq qarating',
    17: 'Kamerani o‘ngroq qarating',
    18: 'Xato: kod 18',
    19: 'Yuzingizni o‘nga buring',
    20: 'Yuzingizni chapga buring',
    21: 'Boshingiz juda o‘nga yegilgan',
    22: 'Boshingiz juda chapga yegilgan',
    23: 'Boshingizni ko‘taring',
    24: 'Boshingizni tushiring',

    25: 'Xato: kod 25',
    26: 'Yuzingizni yopadigan narsalarni olib tashlang',

    27: 'Xato: kod 27',
    28: 'Ko‘zoynaklaringizni yechib oling',

    31: 'Xato: kod 31',
    32: 'Suratga olishni davom eting',
    33: 'Yuz tasvirining past sifati',

    37: 'Davom eting',

    38: 'Kamerani uzoqroq',

    40: 'Xato 40',

    50: 'Xato 50',
    51: 'Ko‘zingizni oching',

    60: 'Xato 60',
    61: 'Ko‘zingizni oching',
    62: 'Ko‘zingizni yuming',
    63: 'Kameraga qarang',
    64: 'Boshingizni o‘nga buring',
    65: 'Boshingizni chapga buring',
    66: 'Kameraga qarang',
    67: 'Boshingizni ko‘taring',
    68: 'Boshingizni tushiring',
};