<template>
    <div>
        <v-dialog
            v-model="showDialog"
            fullscreen
            scrollable
            persistent
        >
            <v-overlay
                :value="isLoader"
                :opacity="0.7"
                color="white"
            >
                <sync-loader :loading="isLoader" color="#e56e07"></sync-loader>
            </v-overlay>
            <v-card id="lunaCard" style="background: transparent;">
                <v-img
                    v-if="showImage"
                    :src="'data:image/jpg;base64, ' + imageData"
                    contain
                    class="mx-auto"
                    style="height: 500px;"
                    :style="isMobile ? 'width: 300px;' : 'width: 500px;'"
                />
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import globalMixin from '@/mixins/globalMixin';
import {Luna} from '@/service/luna';
import {mapGetters} from 'vuex';
import utils from '@/utils/utils';

export default {
    name: 'FaceIdDialogComponent',
    mixins: [globalMixin, Luna],
    data: () => ({
            showDialog: false,
            authDto: null,
            rootNode: null,
            showImage: false,
            imageData: null,
        }
    ),
    computed: {
        ...mapGetters({
            getGlobalLocale: 'locale/getGlobalLocale',
        }),
    },
    methods: {
        async showFaceIdDialog(value) {
            this.showDialog = true;
            const [hasWebcam] = await utils.checkEnumerateDevices();
            if (hasWebcam) {
                const accessPermission = await utils.accessWebcamAndMicrophone({audio: false});
                if (accessPermission) {
                    this.authDto = value;
                    this.showDialog = true;
                    this.init();
                } else
                    this.showDialog = false;
            } else
                this.showDialog = false;
        },
        async closeFaceIdDialog() {
            this.showDialog = false;
            await this.onStop();
            this.$emit('close');
        },
        init() {
            setTimeout(async () => {
                setTimeout(async () => {
                    await this.onStop();
                    this.$emit('timeOut');
                }, 123000);

                let lunaCard = document.getElementById('lunaCard');
                this.rootNode = document.createElement('div');
                this.rootNode.id = 'vllunapass';
                this.rootNode.style.width = '100%';
                this.rootNode.style.height = '100%';
                lunaCard.appendChild(this.rootNode);

                await this.initLuna(this.rootNode, this.getGlobalLocale, this.$vuetify.breakpoint).
                    then((lunaResult) => {
                        const decoded = JSON.parse(atob(lunaResult.jwt.split('.')[1]));
                        if (decoded.isOk && decoded.livenessWasSuccessful) {
                            this.onStop();

                            this.imageData = decoded.bestshot;

                            this.rootNode.remove();

                            this.showImage = true;

                            this.authDto.jwt = lunaResult.jwt;
                            this.$emit('checkByPinAndImageResult', this.authDto);
                        }
                    }).
                    catch((error) => {
                        console.log(error);
                    });
            }, 3000);
        },
    },
    async beforeDestroy() {
        await this.onStop();
    },
};
</script>

<style scoped>
</style>